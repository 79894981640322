[hidden],
.hidden {
    display: none !important;
}

.row {
    margin-right: 0;
    margin-left: 0;
}

.bold {
    font-weight: bold !important;
}

img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
}

a {
    cursor: pointer;
    -webkit-transition: 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

a.disabled {
    pointer-events: none;
    cursor: default;
}

select:focus {
    outline: none;
}

.card-container {
    margin: 0 auto;
}

.form-card {
    width: 100%;
}

.mat-icon {
    user-select: none;
}

button:focus {
    outline: unset;
}

.text-center {
    text-align: center !important;
}

.d-block {
    display: block !important;
}

.w-100 {
    width: 100% !important;
}

// spacers
.nav-spacer,
.flex-stretch {
    flex: 1 0 auto;
}

.line-full {
    width: 100%;
    height: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.line-full-max {
    width: 100vw;
    position: absolute;
    left: 0;
    height: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.content-wrapper {
    margin: auto;
    position: relative;
}

.mat-typography {
    .title-head {
        text-align: center;
        margin-top: 10px;
    }

    h1.title-head,
    h2.title-head {
        word-break: break-word;
        @include font-size($h2-font-size);
    }
}

// forms
.form-group,
.form-input {
    width: 100%;
}

.form-input.mat-form-field {
    .mat-form-field-subscript-wrapper {
        position: relative;
    }

    .mat-form-field-wrapper {
        padding-bottom: 1em;
    }
}

.form-group {
    margin-bottom: 1rem;
}
