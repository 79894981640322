@import "./variables.scss";

.main {
    .main-private,
    .main-public {
        .app-navigation {
            .mat-nav-list {
                .mat-list-item {
                    color: $dark-primary-text;

                    &.active {
                        background: $background-color-black-2;
                    }
                }
            }
        }
    }

    .main-private {
        .app-ratio-bar {
            .badge-user {
                border-color: $primary-color;
            }
        }
    }
}

.custom-snackbar-container .custom-snackbar {
    &:not(:last-of-type) {
        border-bottom-color: $light-gray;
    }
}

footer {
    background-color: $primary-color;
    color: $dark-primary-text;
}

// text color
.text-blue,
a {
    @include set-property-two-colors(color, #0d6efd, rgb(149, 149, 255));
}

.text-red {
    color: red;
}

.text-donate {
    color: #f10000;
}

.text-green {
    color: #198754;
}

.text-orange {
    color: #fd7e14;
}

.text-yellow {
    color: #ffc107;
}

.text-gold {
    color: gold;
}

.custom-snackbar-container .custom-snackbar {
    &:not(:last-of-type) {
        border-bottom-color: $light-gray;
    }
}

.mat-simple-snackbar-action {
    color: inherit;
}

// alert
.alert-message {
    .btn-close {
        @include set-property-two-colors(color, #000, #fff);
    }

    &.danger {
        @include set-property-two-colors(color, #58151c, #ea868f);
        @include set-property-two-colors(border-color, #f1aeb5, #842029);
        @include set-property-two-colors(background-color, #f8d7da, #2c0b0e);
    }

    &.success {
        @include set-property-two-colors(color, #0a3622, #75b798);
        @include set-property-two-colors(border-color, #a3cfbb, #0f5132);
        @include set-property-two-colors(background-color, #d1e7dd, #051b11);
    }

    &.info {
        @include set-property-two-colors(color, #055160, #6edff6);
        @include set-property-two-colors(border-color, #9eeaf9, #087990);
        @include set-property-two-colors(background-color, #cff4fc, #032830);
    }

    &.warning {
        @include set-property-two-colors(color, #664d03, #ffda6a);
        @include set-property-two-colors(border-color, #ffe69c, #997404);
        @include set-property-two-colors(background-color, #fff3cd, #332701);
    }
}
