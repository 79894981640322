@mixin theme-colors($theme) {
    $theme-colors: map-get($theme, color);
    $_background: map-get($theme-colors, background);
    $_foreground: map-get($theme-colors, foreground);
    $background-color: map-get($_background, background);
    $text-color: map-get($_foreground, text);
    $hint-text-color: map-get($_foreground, hint-text);
    $icon-color: map-get($_foreground, icon);
    $card-background-color: map-get($_background, card);
    $primary: map-get($theme-colors, primary);

    $primary-color: map-get($primary, default);

    .main {
        .main-container {
            .mat-icon {
                color: $icon-color;
            }
        }

        .main-public {
            .top-page {
                .mat-icon {
                    color: $icon-color;
                }
            }
        }

        .main-private {
            .app-navigation {
                .user-menu {
                    .mat-icon {
                        color: $icon-color;
                    }
                }
            }
        }

        .main-content {
            .content-body,
            .container-fluid {
                color: $text-color;
            }

            .mat-tab-group .mat-tab-header {
                box-shadow:
                    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
                    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                // transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
                padding: 0.3em;
                border-radius: 4px;

                color: $text-color;
                background: $card-background-color;
            }
        }

        // footer
        .footer-container {
            .disclaimer {
                color: $hint-text-color;
                background-color: $card-background-color;
            }
        }

        // form input
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            border: 0;
            -webkit-text-fill-color: $text-color;
            background-color: $card-background-color !important;
            -webkit-box-shadow: $card-background-color 0 0 0 50px inset !important;
            box-shadow: $card-background-color 0 0 0 50px inset !important;
        }
    }

    .toggle-dark-mode {
        color: $icon-color;
    }

    .spinner .path {
        stroke: $primary-color;
    }

    .line-full,
    .line-full-max {
        background-color: $primary-color;
    }
}
