.background-image {
    background-image: url(./../assets/images/logo/logo-bg.png);
    background-repeat: no-repeat;
    position: absolute;
    z-index: -10;
    width: 461px;
    height: 484px;
}

.logo {
    background-image: url(./../assets/images/logo/logo.png);
    background-repeat: no-repeat;
    width: 170px;
    height: 26px;
    display: block;
    text-indent: -999px;
    overflow: hidden;
    z-index: 1;
    position: relative;
}

.main {
    .main-public .background-image {
        top: -215px;
        left: -250px;
    }

    .main-private .background-image {
        position: fixed;

        &.left {
            bottom: -100px;
            left: -120px;
        }

        &.right {
            top: 56px;
            right: 0;
        }

        @media only screen and (min-width: 600px) {
            &.right {
                top: 64px;
            }
        }

        @media only screen and (max-width: 1000px) {
            &.left {
                display: none;
            }
        }
    }

    .no-layout .background-image {
        top: -215px;
        left: -250px;
    }
}
