$font-size-normal: 1rem !default; // 16px
$font-size-normal-2: 1.125rem !default; // 18px
$font-weight-normal: 400 !default; //normal
$font-weight-semi: 500 !default;

$text-color-white: white !default;
$background-color-black-2: rgba(0, 0, 0, 0.04) !default;

$light-gray: #e3e3e3 !default;

$primary-color: #ff9718;
$accent-color: #333333;

$light-text: #f0ede3;
$light-primary-text: $light-text;

$dark-text: #030303;
$dark-primary-text: rgba($dark-text, 0.87);

$light-background: #f0ede3;
$dark-background: #303030; //#252525;
