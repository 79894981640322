/**
 * Generated theme by Material Theme Generator
 * https://materialtheme.arcsine.dev
 * Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48I2ZmOTcxOCIsIj9lcjwjZmZlMGJhIiwiO2VyPCNmZjdhMGV$LCIlPmBePCMzMzMzMzMiLCI~ZXI8I2MyYzJjMiIsIjtlcjwjMjAyMDIwfiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMzAzMDMiLCI~PTwjZjBlZGUzIiwiO1RleHQ8I2YwZWRlMyIsIjs9PCMxODE4MTh$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEyfQ==
 * + https://material.io/design/color/
 * https://www.laudebugs.me/dev/customizing-angular-material-palette
 * http://mcg.mbitson.com/#!?mcgpalette0=%23ff9718&mcgpalette1=%23333333&mcgpalette2=%23f4511e&themename=material-palette
 */

@use "@angular/material" as mat;

@import "./variables.scss";
@import "./_utils.scss";
@import "./_theme-colors.scss";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
// @import 'https://fonts.googleapis.com/css?family=Material+Icons';
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$primary: (
    50: #fff3e3,
    100: #ffe0ba,
    200: #ffcb8c,
    300: #ffb65d,
    400: #ffa73b,
    500: #ff9718,
    600: #ff8f15,
    700: #ff8411,
    800: #ff7a0e,
    900: #ff6908,
    A100: #ffffff,
    A200: #fff8f4,
    A400: #ffd6c1,
    A700: #ffc6a7,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000
    )
);

$accent: (
    50: #e7e7e7,
    100: #c2c2c2,
    200: #999999,
    300: #707070,
    400: #525252,
    500: #333333,
    600: #2e2e2e,
    700: #272727,
    800: #202020,
    900: #141414,
    A100: #ef6e6e,
    A200: #ea4040,
    A400: #f60000,
    A700: #dd0000,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff
    )
);

// Foreground Elements

// Light Theme Text
$dark-accent-text: rgba($dark-primary-text, 0.65);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-hint-text: rgba($dark-primary-text, 0.44);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$bithdtv-ui-light-theme-foreground: (
    base: black,
    divider: $dark-dividers,
    dividers: $dark-dividers,
    disabled: $dark-disabled-text,
    disabled-button: rgba($dark-text, 0.26),
    disabled-text: $dark-disabled-text,
    elevation: black,
    secondary-text: $dark-accent-text,
    hint-text: $dark-hint-text,
    accent-text: $dark-accent-text,
    icon: $dark-accent-text,
    icons: $dark-accent-text,
    text: $dark-primary-text,
    slider-min: $dark-primary-text,
    slider-off: rgba($dark-text, 0.26),
    slider-off-active: $dark-disabled-text
);

// Dark Theme text
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-hint-text: rgba($light-primary-text, 0.7);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$bithdtv-ui-dark-theme-foreground: (
    base: $light-text,
    divider: $light-dividers,
    dividers: $light-dividers,
    disabled: $light-disabled-text,
    disabled-button: rgba($light-text, 0.3),
    disabled-text: $light-disabled-text,
    elevation: black,
    hint-text: $light-hint-text,
    secondary-text: $light-accent-text,
    accent-text: $light-accent-text,
    icon: $light-text,
    icons: $light-text,
    text: $light-text,
    slider-min: $light-text,
    slider-off: rgba($light-text, 0.3),
    slider-off-active: rgba($light-text, 0.3)
);

// Background config
// Light bg
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten($dark-background, 20%);
$dark-bg-alpha-4: rgba($dark-background, 0.04);
$dark-bg-alpha-12: rgba($dark-background, 0.12);

$bithdtv-ui-light-theme-background: (
    background: $light-background,
    status-bar: $light-bg-darker-20,
    app-bar: $light-bg-darker-5,
    hover: $dark-bg-alpha-4,
    card: $light-bg-lighter-5,
    dialog: $light-bg-lighter-5,
    tooltip: $dark-bg-tooltip,
    disabled-button: $dark-bg-alpha-12,
    raised-button: $light-bg-lighter-5,
    focused-button: $dark-focused,
    selected-button: $light-bg-darker-20,
    selected-disabled-button: $light-bg-darker-30,
    disabled-button-toggle: $light-bg-darker-10,
    unselected-chip: $light-bg-darker-10,
    disabled-list-option: $light-bg-darker-10
);

// Dark bg
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba($light-text, 0.04);
$light-bg-alpha-12: rgba($light-text, 0.12);

// Background palette for dark themes.
$bithdtv-ui-dark-theme-background: (
    background: $dark-background,
    status-bar: $dark-bg-lighter-20,
    app-bar: $dark-bg-lighter-5,
    hover: $light-bg-alpha-4,
    card: $dark-bg-lighter-5,
    dialog: $dark-bg-lighter-5,
    tooltip: $dark-bg-lighter-20,
    disabled-button: $light-bg-alpha-12,
    raised-button: $dark-bg-lighter-5,
    focused-button: $light-focused,
    selected-button: $dark-bg-lighter-20,
    selected-disabled-button: $dark-bg-lighter-30,
    disabled-button-toggle: $dark-bg-lighter-10,
    unselected-chip: $dark-bg-lighter-20,
    disabled-list-option: $dark-bg-lighter-10
);

$bithdtv-ui-primary: mat.define-palette($primary);
$bithdtv-ui-accent: mat.define-palette($accent);

$bithdtv-ui-light-theme: mat.define-light-theme(
    (
        color: (
            primary: $bithdtv-ui-primary,
            accent: $bithdtv-ui-accent
        ),
        typography: mat.define-legacy-typography-config(),
        density: 0
    )
);

$bithdtv-ui-light-theme: modify-section($bithdtv-ui-light-theme, background, $bithdtv-ui-light-theme-background);
$bithdtv-ui-light-theme: modify-section($bithdtv-ui-light-theme, foreground, $bithdtv-ui-light-theme-foreground);

$bithdtv-ui-dark-theme: mat.define-dark-theme(
    (
        color: (
            primary: $bithdtv-ui-primary,
            accent: $bithdtv-ui-accent
        )
    )
);

$bithdtv-ui-dark-theme: modify-section($bithdtv-ui-dark-theme, background, $bithdtv-ui-dark-theme-background);
$bithdtv-ui-dark-theme: modify-section($bithdtv-ui-dark-theme, foreground, $bithdtv-ui-dark-theme-foreground);

@include mat.all-legacy-component-themes($bithdtv-ui-light-theme);
@include theme-colors($bithdtv-ui-light-theme);

.dark-mode {
    @include mat.all-legacy-component-colors($bithdtv-ui-dark-theme);
    @include theme-colors($bithdtv-ui-dark-theme);
}

body {
    --primary-color: #{$primary-color};
    --text-primary-color: #{$dark-primary-text};
    --accent-color: #{$accent-color};
    --text-accent-color: #{$light-primary-text};
    --light-background: #{$light-background};
    --dark-background: #{$dark-background};
}

@import "./_utils2.scss";
