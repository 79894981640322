@mixin set-property-color($property, $key1, $key2) {
    & {
        #{$property}: map-get(map-get($bithdtv-ui-light-theme, $key1), $key2);
    }

    .dark-mode & {
        #{$property}: map-get(map-get($bithdtv-ui-dark-theme, $key1), $key2);
    }
}

@mixin set-property-color-important($property, $key1, $key2) {
    & {
        #{$property}: map-get(map-get($bithdtv-ui-light-theme, $key1), $key2) !important;
    }

    .dark-mode & {
        #{$property}: map-get(map-get($bithdtv-ui-dark-theme, $key1), $key2) !important;
    }
}

@mixin set-property-color-body($property, $key1, $key2) {
    & {
        #{$property}: map-get(map-get($bithdtv-ui-light-theme, $key1), $key2);
    }

    &.dark-mode {
        #{$property}: map-get(map-get($bithdtv-ui-dark-theme, $key1), $key2);
    }
}

@mixin set-property-two-colors($property, $value, $valueDark) {
    & {
        #{$property}: $value;
    }

    .dark-mode & {
        #{$property}: $valueDark;
    }
}

@mixin set-property-complex($property, $key1, $key2, $prefix, $sufix) {
    & {
        #{$property}: #{$prefix map-get(map-get($bithdtv-ui-light-theme, $key1), $key2) $sufix};
    }

    .dark-mode & {
        #{$property}: #{$prefix map-get(map-get($bithdtv-ui-dark-theme, $key1), $key2) $sufix};
    }
}

@function get-color($key1, $key2) {
    @return map-get(map-get($bithdtv-ui-light-theme, $key1), $key2);
}

@function get-color-dark($key1, $key2) {
    @return map-get(map-get($bithdtv-ui-dark-theme, $key1), $key2);
}
