@import "./bootstrap.scss";
@import "./theme.scss";
@import "./colors.scss";
@import "./misc.scss";
@import "./logo.scss";

html,
body {
    box-sizing: border-box;
    font-weight: $font-weight-normal;
    height: 100%;
    width: 100%;
    user-select: text !important;

    a:hover {
        text-decoration: none;
        color: inherit;
    }
}

body {
    margin: 0;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.main {
    .main-public,
    .main-private,
    .no-layout {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        flex: 1 1 0%;
        -ms-flex: 1 1 auto;
    }

    .main-private {
        .main-container {
            &.mat-sidenav-container {
                overflow: unset;
                margin-top: 56px;
                flex: 1 1 0%;
                -ms-flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: 100%;

                .mat-sidenav-content {
                    position: relative;
                    z-index: 1;
                    display: flex;
                    flex-direction: column;
                    flex: 1 1 0%;
                    -ms-flex: 1 1 auto;
                    overflow: unset;
                    height: unset;
                    width: auto;
                }
            }

            @media only screen and (min-width: 600px) {
                &.mat-sidenav-container {
                    margin-top: 64px;
                }
            }
        }
    }

    .main-content {
        position: relative;
        margin: 0;
        padding: 0;
        width: 100%;
        min-height: 200px;
        flex: 1 1 0%;
        -ms-flex: 1 1 auto;

        .content-vertical,
        .content-body {
            display: flex;
            flex-direction: column;
            gap: 2em;
            overflow-x: auto;
            padding-bottom: 1em;
        }

        .content-body:not(.container),
        .content-vertical.content-body,
        .container-fluid.content-body {
            max-width: calc(100vw - 2em);
        }

        .content-body,
        .container-fluid,
        .container {
            padding: 1.33em 1em;
        }

        .content-body-public {
            margin: 1.2em 0 1em 0;
        }
    }
}

mat-sidenav {
    &.mat-drawer {
        position: fixed;
        height: 100vh;
        min-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 70px;
        z-index: 100;
        min-width: 260px;
        width: 300px;
        max-width: 100vw;
    }

    mat-nav-list {
        overflow-y: auto;

        a mat-icon {
            font-size: $font-size-normal-2;
            margin-right: 10px;
        }

        a .menu-reload-btn mat-icon {
            margin-right: 0;
        }
    }

    .mat-selection-list {
        overflow-y: auto;
    }

    h3 {
        margin-bottom: 0px;
        margin-left: 20px;
    }
}

// app-navigation component

.app-navigation .mat-list-base .mat-list-item .mat-list-item-content {
    padding: 0 12px;
    justify-content: center;
}

.main-private .app-navigation .mat-list-base .mat-list-item .mat-list-item-content {
    font-weight: $font-weight-semi;
}

//topnav

.user-menu .mat-menu-content a.mat-menu-item,
.staff-menu .mat-menu-content a.mat-menu-item {
    // color: $text-color-black;
    .mat-icon {
        margin-right: 8px;
        margin-top: -3px;
    }

    .ng-fa-icon {
        user-select: none;
        display: inline-block;
        fill: currentColor;
        margin-right: 11px;
        margin-left: 3px;
    }

    &:hover {
        text-decoration: none;
    }
}

.mat-accordion .mat-expansion-panel:not(.mat-expanded),
.mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
    margin-bottom: 7px;
}

.cdk-global-scrollblock {
    overflow: unset;
}

// .mat-accordion {
//     .mat-expansion-panel {
//         &:first-of-type {
//             margin-top: 3px;
//         }

//         &:last-of-type {
//             margin-bottom: 4em;
//         }
//     }
// }

// .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']) {
//     background: white;
// }

.loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    // background: $background-color-white;
    background: transparent;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 250px;

    opacity: 0;
    visibility: hidden;
    animation: loadingShadeFadeIn 2s ease forwards;
}

.loading-shade-relative {
    position: relative;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: text;
    z-index: 10;
    background: transparent;

    opacity: 0;
    visibility: hidden;
    animation: loadingShadeFadeIn 2s ease forwards;
}

.loading-shade-over {
    position: absolute;
    top: 2em;
    left: 0;
    right: 0;
    background: transparent;
    z-index: 100;
    min-height: 125px;

    opacity: 0;
    visibility: hidden;
    animation: loadingShadeFadeIn 2s ease forwards;
}

@keyframes loadingShadeFadeIn {
    from {
        opacity: 0;
        visibility: hidden;
    }
    to {
        opacity: 1;
        visibility: visible;
    }
}

.error-div:empty,
.loading-shade-over:empty,
.loading-shade-relative:empty,
.loading-div:empty {
    display: none;
}

.error-div {
    min-height: 150px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > h3,
    > h4,
    > h5 {
        font-weight: $font-weight-semi;
        font-size: large;
    }
}

.loading-div {
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1em 0;
}

.custom-snackbar-container {
    &.mat-snack-bar-container {
        max-width: 100%;
        padding: 5px 16px;
        line-height: unset;
    }
}

.img-loading {
    margin: 0 0.3em;
}

.form-group .mat-button-base {
    margin-right: 0.5em;

    &:last-child {
        margin-right: 0;
    }
}

.mat-card .mat-card-header .mat-card-header-text {
    width: 100%;
}

@keyframes loadingTableFadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.6;
    }
}

.table-container {
    position: relative;
    overflow: auto;
    width: 100%;
    max-width: 100%;
    user-select: text;

    .mat-table {
        width: 100%;

        &.loading {
            min-height: calc(125px + 4em);
            user-select: none;
            opacity: 1;
            animation: loadingTableFadeOut 2s ease forwards;
        }

        @media only screen and (max-width: 500px) {
            td.mat-cell:first-of-type,
            td.mat-cell:last-of-type,
            td.mat-cell {
                padding: 7px;
            }
        }
    }
}

.grid-card-container {
    width: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    margin: 0 auto;

    .card {
        width: fit-content;
        text-decoration: none;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        position: relative;
        top: 0;
        transition: all 0.1s ease-in;

        .mat-card {
            height: 100%;

            .mat-card-subtitle {
                font-size: $font-size-normal-2;
            }

            .mat-card-header {
                text-align: center;
                justify-content: center;
            }

            .mat-card-actions:empty + .mat-card-content {
                padding-bottom: 25px;
            }

            .mat-card-actions {
                bottom: 20px;
                position: absolute;
                width: 96%;
            }

            @media only screen and (max-width: 400px) {
                .mat-card-actions {
                    position: relative;
                    bottom: 0;
                    width: unset;
                }
            }

            .action-btns {
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                > button {
                    margin-right: 0.5em;
                }
            }
        }
    }

    .card:hover {
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
    }

    > .footer-card,
    > .header-card {
        grid-column: 1 / -1;
        grid-template-columns: 1fr;
    }
}
